export const columns = [
	{
		prop: 'userName',
		label: '员工姓名',
		width: '125px',
	},
	{
		prop: 'roleName',
		label: '员工角色',
	},
	{
		prop: 'companyName',
		label: '所属公司',
	},
	{
		prop: 'periodMonth',
		label: '数据月份',
		width: '125px',
	},
	{
		prop: 'cars',
		label: '当月负责车辆数',
		width: '135px',
	},
	{
		prop: 'accidents',
		label: '当月事故数',
		width: '125px',
	},
	{
		prop: 'errAccidents',
		label: '当月有责事故数',
		width: '135px',
	},
	{
		prop: 'errAccidentsMom',
		label: '当月有责事故环比',
		width: '145px',
	},
	{
		prop: 'serviceCars',
		label: '当月维修台次',
		width: '125px',
	},
	{
		prop: 'keepCars',
		label: '当月保养台次',
		width: '125px',
	},
	{
		prop: 'wearCars',
		label: '当月易损件台次',
		width: '135px',
	},
	{
		prop: 'serviceAmount',
		label: '当月维修金额(元)',
		width: '135px',
	},
	{
		prop: 'serviceAmountMom',
		label: '当月维修金额环比',
		width: '145px',
	},
	{
		prop: 'keepAmount',
		label: '当月保养金额(元)',
		width: '135px',
	},
	{
		prop: 'keepAmountMom',
		label: '当月保养金额环比',
		width: '145px',
	},
	{
		prop: 'wearAmount',
		label: '当月易损件金额(元)',
		width: '150px',
	},
	{
		prop: 'wearAmountMom',
		label: '当月易损件金额环比',
		width: '155px',
	},
]
export const setting = {
	expand: true,
	filters: [
		{
			attr: 'companyId',
			label: '所属公司',
			type: 'select',
			placeholder: '请选择所属公司',
			options: [],
		},
		{
			attr: 'userId',
			label: '员工姓名',
			type: 'select',
			placeholder: '请选择员工姓名',
			options: [],
		},
		{
			attr: 'dataDate',
			value: '',
			shortcuts: [],
			clearable: false,
			label: '数据时间',
			type: 'month',
		},
	],
}
